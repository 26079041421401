
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';

import {Watch} from 'vue-property-decorator';
import {Promoted, IPromotedForm} from '@/model';
import {Work} from './work';
import {ElMessage, ElTabs, ElTabPane, ElUpload, ElPopconfirm} from 'element-plus';
import {App, Format, Server} from '@/services';
import format from '@/services/format';
import {ISubdomain, ISubdomainResult, IMatchListResult, IActivity, IActivityResult} from '@/model';
import {contact} from '@/services/server/web/contact';
import {IContactResult} from '@/model/contact';
import {basic} from '@/config';

@Options({
  components: {
    ElTabs,
    ElTabPane,
    ElUpload,
    ElPopconfirm
  }
})
export default class Login extends Vue {
  userinfo = App.getUserinfo();
  basic = basic;
  submitLoading = false;
  checkIndex = 1;
  labelPosition = 'left';
  // 直播链接的分页数据
  withPage = 1;
  withTotal = 0;
  pageSize = 50;
  info: ISubdomain = {
    note: '',
    domain: ''
  };
  line: null | string = '';
  line_group: null | string = '';
  ig: null | string = '';
  fb: null | string = '';
  zalo: null | string = '';
  rules = {
    username: [{validator: Promoted.validateInput, trigger: 'blur'}],
    password: [{validator: Promoted.validateInput, trigger: 'blur'}]
  };
  link: Array<ISubdomain> = [];
  activeList: IActivity[] = [];
  showModel = false;
  isEdit = false;
  isAdd = false;
  isLoading = false;
  showLink = false;
  text = '';
  textarea = {
    innerText: ''
  };
  aid = '';
  sharedUrl = '';
  liveLink: Array<any> = [];
  livePopup = {
    show: false,
    data: null,
    imageUrl: ''
  };

  get sharelink() {
    return `${this.sharedUrl}/cz/${this.aid}/`;
  }
  get igText() {
    return `${this.text} \r\n ${this.sharelink}`;
  }

  @Watch('checkIndex')
  onCheckIndexChange(val: number) {
    console.log('onCheckIndexChange val: ', val);
    switch (val) {
      case 2:
        this.getLink();
        this.getActived();
        if (!document.querySelector('#zalo')) {
          const script = document.createElement('script');
          script.id = 'zalo';
          script.src = 'https://sp.zalo.me/plugins/sdk.js';
          document.body.append(script);
        }
        break;
      case 3:
        this.read();
        break;
      case 4:
        this.withPage = 1;
        this.withTotal = 0;
        this.liveLink = [];
        this.getLiveLink();
        break;
      case 5:
        this.withPage = 1;
        this.withTotal = 0;
        this.liveLink = [];
        this.getBtiLiveLink();
        break;
    }
  }
  created() {
    // this.getLink();
  }
  withPages(e: number) {
    this.withPage = e;
    const {checkIndex} = this;
    switch (checkIndex) {
      case 4:
        this.getLiveLink();
        break;
      case 5:
        this.getBtiLiveLink();
        break;
    }
  }
  handleAvatarSuccess(res: any) {
    this.livePopup.imageUrl = res.data.path;
  }
  beforeAvatarUpload(file: any) {
    const isJPG = file.type === 'image/jpeg' || 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      ElMessage('上传图片只能是 JPG 和 PNG 格式!');
    }
    if (!isLt2M) {
      ElMessage('上传图片大小不能超过 1MB!');
    }
    return isJPG && isLt2M;
  }
  uploadPic(item: any) {
    this.livePopup = {
      show: true,
      data: item,
      imageUrl: item.status === 1 ? '' : ''
    };
  }
  async fetchUploadPic() {
    this.isLoading = true;
    const data: any = this.livePopup.data;
    const res = await Server.Web.Operation.uploadLivePic<any>({
      cover: this.livePopup.imageUrl,
      agent_id: this.userinfo?.userId,
      match_code: data?.code
    });
    if (!(res instanceof Error)) {
      ElMessage(this.$t('rp_operate') + this.$t('ui_success'));
      this.livePopup = {
        show: false,
        data: null,
        imageUrl: ''
      };
      this.getLiveLink();
    } else {
      this.isLoading = false;
      ElMessage(JSON.stringify(res.name === '202') ? this.$t('openLive_error') : res.message);
    }
  }
  async endLive(item: any) {
    this.isLoading = true;
    const res = await Server.Web.Operation.endLive<any>({
      agent_id: this.userinfo?.userId,
      match_code: item?.code
    });
    if (!(res instanceof Error)) {
      ElMessage(this.$t('rp_operate') + this.$t('ui_success'));
      this.getLiveLink();
    } else {
      this.isLoading = false;
      ElMessage(res.message);
    }
  }
  async getActived() {
    const activityResult = await Server.Web.Operation.activityList<IActivityResult>();
    if (!(activityResult instanceof Error)) {
      this.activeList = activityResult.list[0].active;
    }
  }
  async getLink() {
    this.isLoading = true;
    const res = await Server.Web.Operation.linkMember<ISubdomainResult>();
    if (!(res instanceof Error)) {
      this.link = res.list;

      this.isLoading = false;
    }
  }
  async getLiveLink() {
    this.isLoading = true;
    const res = await Server.Web.Operation.newLiveLink<IMatchListResult>({
      page: this.withPage,
      size: this.pageSize,
      agent_id: this.userinfo?.userId
    });
    if (!(res instanceof Error)) {
      this.liveLink = res.data;
      this.withTotal = res.total;

      this.isLoading = false;
    }
  }
  async getBtiLiveLink() {
    this.isLoading = true;
    const res = await Server.Web.Operation.btiLiveLink<IMatchListResult>({
      page: this.withPage,
      size: this.pageSize
    });
    if (!(res instanceof Error)) {
      this.liveLink = res.data;
      this.withTotal = res.total;

      this.isLoading = false;
    }
  }
  getLiveTitle(item: any) {
    return `${item?.home.name} VS ${item?.away.name} (${this.$t(
      'game_time'
    )}：${Format.formatYMDHMS(new Date(item.estimate * 1000))})`;
  }
  copyLiveContent(item: any) {
    const {checkIndex} = this;
    // 由于不同的语言的918地址前缀不一样  所以我们这里用正则处理一下
    const lang: any = {
      vn: 'vi.',
      en: 'en.',
      th: ''
    };
    const replace = lang[this.$i18n.locale] ?? '';
    const cacheUserInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
    let link = '';
    // bti链接的处理方式不太一样
    if (checkIndex == 5) {
      link = `http://${this.userinfo?.domain}.aia998.com/m/${cacheUserInfo.platform_code}/${item.app_match_code}`;
      this.copy(link);
    } else {
      // 没有hosts就使用918链接
      const hosts = cacheUserInfo?.hosts?.length ? cacheUserInfo.hosts[0] : 'aia918.com';
      const url = `http://${replace}${hosts}`;
      link = `${this.getLiveTitle(item)} ${this.$t('view_game')} ${url}/match/detail/${
        item.id
      }?userId=${this.userinfo?.userId}&platformCode=${cacheUserInfo.platform_code}`;
      this.copy(link);
    }
  }
  async reGetImg(key: null | string) {
    const _key = key || 'line';
    const id = new Map([
      ['24', [52, 54]],
      ['9', [55, 57]],
      ['19', [58, 60]],
      ['20', [61, 63]],
      ['b', [64, 66]],
      ['16', [67, 69]],
      ['21', [70, 72]],
      ['23', [73, 75]],
      ['10', [76, 78]],
      ['22', [79, 81]],
      ['12', [82, 84]],
      ['17', [85, 87]],
      ['18', [88, 90]],
      ['15', [91, 93]],
      ['14', [94, 96]],
      ['a', [97, 99]],
      ['13', [100, 102]],
      ['11', [103, 105]],
      ['34', [118, 119]],
      ['35', [120, 121]],
      ['45', [154, 155]]
    ]);
    const hasHanldeIdgroup = id.get(this.aid) || [];
    const typeId = hasHanldeIdgroup
      ? hasHanldeIdgroup[
          key === null ? Math.floor(Math.random() * 2) : ['line', 'fb'].includes(_key) ? 0 : 1
        ]
      : null;
    if (typeId) {
      const [resultWord] = await Promise.all([
        fetch(`https://www.craw248000.com/api/getContentList?type_id=${typeId}&strip=1`)
      ]);
      // const res = rs.json()
      const [word] = await Promise.all([resultWord.text()]);
      const [wordJson] = [JSON.parse(word)];
      console.log('wordJson: ', wordJson);
      if (resultWord.status === 200) {
        if (wordJson.result !== 0) {
          this.text = wordJson.data[0]?.content;
        } else {
          this.text = '';
        }
      } else {
        this.text = '';
      }
    }
  }
  getSharedUrl({domain, id, type}: {domain: string; id: string; type: string}) {
    const idMap = new Map([
      ['football', 'b'],
      ['recommend', 'a']
    ]);
    const actId = idMap.get(type) || id;
    return `https://${domain}/cz/${actId}/`;
  }
  showClick(item: {type: string; atdcl_id: number | string; domain: string}) {
    const aidHandle = new Map([
      ['football', 'b'],
      ['recommend', 'a']
    ]);
    this.aid = aidHandle.get(item.type) || item.atdcl_id + '';
    this.reGetImg(null);
    this.showLink = true;
    this.sharedUrl = `${location.protocol}//${item.domain}`;
  }

  chnageText() {
    this.text = this.textarea.innerText;
  }
  handleCopySuccess(val: string) {
    this.copy(val);
    console.log({val});
    setTimeout(() => {
      // on confirm
      this.openShareLink({key: 'ig'});
    }, 500);
  }
  openShareLink({key}: {key: string}) {
    switch (key) {
      case 'line':
        window.open(
          `http://line.me/R/msg/text/?${this.text}%0A${encodeURIComponent(this.sharelink)}`
        );
        break;
      case 'fb':
        window.open(
          `https://www.facebook.com/dialog/share?href=${encodeURIComponent(
            this.sharelink
          )}&display=popup&ref=plugin&app_id=1085192225327998&quote=${this.text}`
        );
        break;
      case 'ig':
        // this.handleCopySuccess(evt)
        window.open(`http://www.instagram.com/`);
        break;
      case 'zalo':
        if (screen.height === window.innerHeight) {
          window.open(`https://chat.zalo.me/`);
        }
        break;
      case 'mail':
        window.open(`mailto:someone@yoursite.com?body=${this.igText}`);
        break;
    }

    this.showLink = false;
  }
  modelRef = reactive<IPromotedForm>({
    is_member: '1',
    user_account: '',
    password: ''
  });
  mounted() {
    this.modelRef.is_member = '1';
  }
  handleSubmit() {
    const res = this.$refs.formRef as any;
    this.submitLoading = true;
    res.validate(async (v: any) => {
      if (v) {
        const res = await Work.setMember<any>(this.modelRef);
        if (!(res instanceof Error)) {
          ElMessage(res.message);
          this.submitLoading = false;
        } else {
          ElMessage.error(res.message);
        }
      }
      this.submitLoading = false;
    });
  }
  resetFields() {
    this.modelRef = {
      is_member: '1',
      user_account: '',
      password: ''
    };
  }
  copy(val: string) {
    format.copyTextToClipboard(val);
    ElMessage(this.$t('ui_successful_copy'));
  }
  showEdit(item: {note: string}) {
    this.showModel = true;
    this.isEdit = true;
    this.info = {...this.info, ...item};
  }
  showAdd() {
    this.showModel = true;
    this.isAdd = true;
  }
  onCancel() {
    this.showModel = false;
  }
  onClosed() {
    this.isAdd = false;
    this.isEdit = false;
    this.info = {
      note: '',
      domain: ''
    };
  }
  async onAdd() {
    // this.loading = true;
    this.isLoading = true;
    const result = await Server.Web.Operation.addSubdomain<ISubdomainResult>({
      note: this.info.note
    });
    if (result instanceof Error) {
      ElMessage({
        message: result.message,
        type: 'error'
      });
    } else {
      ElMessage({
        message: result.message,
        type: 'success'
      });
      setTimeout(() => {
        this.getLink();
      }, 2500);
      this.onCancel();
    }
  }
  async onEdit() {
    this.isLoading = true;
    const result = await Server.Web.Operation.editSubdomain<ISubdomainResult>({
      note: this.info.note,
      domain: this.info.domain
    });
    if (result instanceof Error) {
      ElMessage({
        message: result.message,
        type: 'error'
      });
    } else {
      ElMessage.success({
        message: result.message,
        type: 'success'
      });
      this.getLink();
      this.onCancel();

      this.isLoading = false;
    }
  }
  async read() {
    this.isLoading = true;
    const result = await contact.read<IContactResult>();
    if (!(result instanceof Error)) {
      console.log(result, 'rs');
      const {line, ig, fb, zalo, lineGroup} = result.list[0];
      this.line = line;
      this.ig = ig;
      this.fb = fb;
      this.zalo = zalo;
      this.line_group = lineGroup;
    }
    this.isLoading = false;
  }
  async submit() {
    this.isLoading = true;
    const params = {
      line: this.line,
      ig: this.ig,
      fb: this.fb,
      zalo: this.zalo,
      line_group: this.line_group
    };
    if (!this.line_group) delete params.line_group;
    const result = await contact.edit<IContactResult>(params);
    if (result instanceof Error) {
      ElMessage({
        message: result.message,
        type: 'error'
      });
    } else {
      ElMessage({
        message: result.message,
        type: 'success'
      });
      this.read();
    }

    this.isLoading = false;
  }
}
