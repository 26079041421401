<template>
  <div class="main webstyles">
    <el-card shadow="never" class="cus-card">
      <el-row>
        <div class="form-title">
          <el-radio-group v-model="checkIndex">
            <el-radio-button :label="1">{{ $t('ui_add_member') }}</el-radio-button>
            <el-radio-button :label="2">{{ $t('ui_reg_link') }}</el-radio-button>
            <el-radio-button :label="3">{{ $t('ui_contact_settings') }}</el-radio-button>
            <el-radio-button :label="4">{{ $t('ui_live_link') }}</el-radio-button>
            <el-radio-button :label="5">BTI {{ $t('ui_live_link') }}</el-radio-button>
          </el-radio-group>
        </div>
        <el-col :span="24" v-if="checkIndex == 1">
          <el-form
            :model="modelRef"
            :rules="rules"
            ref="formRef"
            label-width="100px"
            label-position="left"
          >
            <el-form-item :label="$t('ui_user_type')">
              <el-radio-group v-model="modelRef.is_member">
                <el-radio label="1">{{ $t('ui_agent') }}</el-radio>
                <el-radio label="0">{{ $t('ui_member') }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('rp_username')" prop="username">
              <el-input
                v-model="modelRef.user_account"
                :placeholder="$t('ui_please_enter_user_name_new')"
              />
            </el-form-item>
            <el-form-item :label="$t('team__password')" prop="password">
              <el-input
                v-model="modelRef.password"
                :placeholder="$t('login__passwordPlaceholder')"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSubmit" :loading="submitLoading">
                {{ $t('team__submit') }}
              </el-button>
              <el-button @click="resetFields">
                {{ $t('team__reset') }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="24" v-if="checkIndex == 2" v-loading="isLoading">
          <el-row type="flex" justify="end">
            <el-col :span="link.length >= 7 ? 6 : 3">
              <el-button
                type="primary"
                plain
                @click="showAdd"
                class="u_m--t20"
                :disabled="link.length >= 7"
              >
                {{ $t(link.length > 6 ? 'ui_toomuch' : 'ui_add_link') }}
              </el-button>
            </el-col>
          </el-row>
          <el-tabs type="border-card" style="margin-top: 10px">
            <el-tab-pane v-for="(item, idx) in link" :key="item.domain" :label="String(idx + 1)">
              <div class="title flex flex-row flex-justify-between">
                <span>{{ item.note }}</span>
                <i
                  class="el-icon-edit-outline"
                  @click="showEdit(item)"
                  style="font-size: 32px;cursor: pointer;"
                ></i>
              </div>
              <div class="input-box">
                <input v-model.trim="item.domain" type="text" class="ipt" disabled />
                <div class="copy" @click="copy(item.domain)">
                  <img src="@/assets/wap/copy.png" alt="" class="icon" />
                </div>
              </div>
              <div class="input-box active" v-for="act in activeList" :key="act.atdcl_id">
                <input v-model.trim="act.name" type="text" class="ipt" disabled />
                <div
                  class="copy"
                  @click="
                    copy(getSharedUrl({domain: item.domain, id: '' + act.atdcl_id, type: act.type}))
                  "
                >
                  <img src="@/assets/wap/copy.png" alt="" class="icon" />
                </div>
                <!-- <div class="shared">
                  <img
                    src="@/assets/wap/icons8-upload-50.svg"
                    alt=""
                    @click.stop.prevent="showClick({...act, domain: item.domain})"
                  />
                </div> -->
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="24" v-if="checkIndex == 3" v-loading="isLoading">
          <el-form
            :label-position="labelPosition"
            label-width="100px"
            :model="formLabelAlign"
            class="u_w--50p"
          >
            <el-form-item label="LINE">
              <el-input v-model="line"></el-input>
            </el-form-item>
            <el-form-item label="LINE GROUP">
              <el-input v-model="line_group"></el-input>
            </el-form-item>
            <el-form-item label="IG">
              <el-input v-model="ig"></el-input>
            </el-form-item>
            <el-form-item label="FB">
              <el-input v-model="fb"></el-input>
            </el-form-item>
            <el-form-item label="Zalo">
              <el-input v-model="zalo"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit" class="u_w--100p">
                {{ $t('team__submit') }}
              </el-button>
            </el-form-item>
          </el-form>
          <div class="">
            <i class="el-icon-warning" style="color:red"></i>
            {{ $t('ui_after_setting') }}
          </div>
        </el-col>
        <el-col :span="24" v-if="checkIndex == 4" v-loading="isLoading">
          <el-tabs type="border-card" style="margin-top: 10px">
            <div class="listBox">
              <div class="input-box active" v-for="item in liveLink" :key="item.atdcl_id">
                <span class="high" v-if="item.bti_code"> [{{ $t('canLive') }}] </span>
                <input :value="getLiveTitle(item)" type="text" class="ipt" disabled />
                <div class="copy" @click="copyLiveContent(item)">
                  <img src="@/assets/wap/copy.png" alt="" class="icon" />
                </div>
                <div
                  v-if="item.tencent.status === 0 && item.bti_code"
                  class="copy"
                  @click="uploadPic(item)"
                >
                  {{ $t('live') }}
                </div>
                <template v-if="item.tencent.status === 1 && item.bti_code">
                  <el-popconfirm
                    class="custom-popover"
                    :title="$t('endLive_confirm')"
                    :confirm-button-text="$t('btn_confirm')"
                    :cancel-button-text="$t('btn_cancel')"
                    @confirm="endLive(item)"
                  >
                    <template #reference>
                      <div class="copy">{{ $t('match__stats12') }}</div>
                    </template>
                  </el-popconfirm>
                  <div class="copy" @click="copy(item.tencent.push.rtmp)">
                    {{ $t('copyPushMedia') }}
                  </div>
                </template>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="withPages"
              :total="withTotal"
              :page-size="pageSize"
            >
            </el-pagination>
          </el-tabs>
        </el-col>
        <el-col :span="24" v-if="checkIndex == 5" v-loading="isLoading">
          <el-tabs type="border-card" style="margin-top: 10px">
            <div class="listBox">
              <div class="input-box active" v-for="item in liveLink" :key="item.atdcl_id">
                <input :value="getLiveTitle(item)" type="text" class="ipt" disabled />
                <div class="copy" @click="copyLiveContent(item)">
                  <img src="@/assets/wap/copy.png" alt="" class="icon" />
                </div>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="withPages"
              :total="withTotal"
              :page-size="pageSize"
            >
            </el-pagination>
          </el-tabs>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog
      v-model="livePopup.show"
      center
      :close-on-click-modal="false"
      width="400px"
      custom-class="c_dialog"
    >
      <!--      <template v-if="livePopup.show" #title>-->
      <!--        <el-row :class="`u_p&#45;&#45;b10 u_t&#45;&#45;center title`" type="flex" align="middle" justify="center">-->
      <!--          <span>{{ getLiveTitle(livePopup.data) }}</span>-->
      <!--        </el-row>-->
      <!--      </template>-->
      <div class="dialog-content">
        <el-upload
          accept="image/png,image/jpeg"
          name="image"
          :action="basic['918Url'] + '/api/common/upload'"
          list-type="picture-card"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :limit="1"
          :disabled="!!livePopup.imageUrl"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-button style="margin-top: 24px" type="primary" @click="fetchUploadPic">{{
          $t('upload')
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      v-model="showModel"
      :show-close="false"
      center
      :close-on-click-modal="false"
      width="600px"
      @closed="onClosed"
    >
      <template #title>
        <div class="dialog_head">
          <div style="margin:auto;font-size: 28px;font-weight: 700;" class="u_fw--bold">
            {{ $t('ui_add_link') }}
          </div>
          <button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn header_close"
            @click="onCancel"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g id="icon/24/X" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  id="编组"
                  mask="url(#mask-2)"
                  stroke="#333333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                >
                  <g
                    transform="translate(11.500000, 12.500000) rotate(-315.000000) translate(-11.500000, -12.500000) translate(0.000000, 1.000000)"
                  >
                    <line x1="0" y1="11.5" x2="23" y2="11.5" id="路径-18"></line>
                    <line x1="11.5" y1="0" x2="11.5" y2="23" id="路径-19"></line>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </template>
      <div class="dialog_main">
        <div class="form__field" style="height: 40px">
          <div style="margin-left: 10px;">
            {{ $t('rp_remark') }}
          </div>
        </div>
        <div class="form__field ">
          <el-input
            class="c_input--longest"
            v-model="info.note"
            clearable
            type="text"
            :placeholder="$t('tip_input_remark')"
            :error-message="$t('')"
            @input="clearError"
            maxlength="20"
          >
          </el-input>
        </div>
        <div class="form__field">
          <div style="margin: 0 10px;color: #EB4444">
            {{ $t('ui_remarksare_limited') }}
          </div>
        </div>
        <div class="flex flex-justify-evenly u_m--t20">
          <el-button
            round
            class="c_button--XL u_w--100p userbtn-bg--submit"
            :icon="'edit'"
            :type="'info'"
            :hairline="true"
            @click="onCancel"
            v-text="$t('btn_cancel')"
          ></el-button>
          <button
            type="button"
            class="c_button--XL userbtn-bg--submit u_main--btn u_w--100p"
            v-if="isAdd"
            @click="onAdd"
            style="margin-left: 10px;"
          >
            <span class="c_button__text c_button__weight">{{ $t('btn_confirm') }}</span>
          </button>
          <button
            type="button"
            class="c_button--XL userbtn-bg--submit u_main--btn u_w--100p"
            v-if="isEdit"
            @click="onEdit"
            style="margin-left: 10px;"
          >
            <span class="c_button__text c_button__weight">{{ $t('btn_confirm') }}</span>
          </button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      v-model="showLink"
      :show-close="false"
      center
      :close-on-click-modal="false"
      width="600px"
    >
      <div class="u_p-t9 u_p-l9 u_w--100p">
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="icon/16/刷新" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M4.9999872,1.65811127 C7.01610099,0.603128058 9.49999894,0.906841915 11.1290042,1.92985367 C12.7580094,2.9529252 13.3064393,3.81607969 13.3064393,3.81607969 L14.7096126,3.01685315 C14.7096126,3.01685315 15,2.87301149 15,3.20868187 L15,8.22803548 C15,8.22803548 15,8.67562087 14.6612371,8.51573373 C14.3762728,8.38130423 11.2946432,6.62893999 10.3235626,6.07611773 C9.79029673,5.83825909 10.258813,5.64502602 10.258813,5.64502602 L11.6128786,4.87110773 C11.6128786,4.87110773 10.8405141,3.90382191 9.71005436,3.39154662 C8.49999467,2.76109648 7.36712959,2.68633707 5.97946401,3.21022068 C5.07458228,3.55176244 4.00923075,4.4263908 3.24188602,5.71830638 L1.70970648,4.67929395 C1.70970648,4.67929395 2.98385848,2.71313929 4.9999872,1.65811127 Z M11,14.3341047 C8.98386914,15.3891029 6.50001601,15.0854488 4.87099584,14.0624221 C3.24190096,13.0393207 2.69357563,12.1761213 2.69357563,12.1761213 L1.29032768,12.9753479 C1.29032768,12.9753479 1,13.1192792 1,12.7835192 L1,7.76421038 C1,7.76421038 1,7.31666982 1.33867329,7.47648226 C1.62365247,7.61098646 4.70535682,9.363276 5.67643738,9.91615801 C6.20970327,10.1540166 5.74120199,10.34719 5.74120199,10.34719 L4.38712137,11.1211381 C4.38712137,11.1211381 5.15951578,12.0883791 6.28996059,12.6006992 C7.49997547,13.2311046 8.63279572,13.3058939 10.020536,12.7819953 C10.925358,12.4404386 11.9907244,11.5658999 12.7580094,10.2739096 L14.2903085,11.3129519 C14.2903085,11.3129519 13.0161565,13.2791364 11,14.3341047 Z"
              id="形状"
              fill="#000"
              fill-rule="nonzero"
            ></path>
          </g>
        </svg>
      </div>
      <div contenteditable="true" class="text-area" @blur="chnageText" ref="textarea">
        {{ text }}
      </div>
      <p>{{ sharelink }}</p>
      <el-row
        type="flex"
        justify="space-between"
        align="center"
        :gutter="10"
        class="u_p-l9 u_p-r9 u_p-t9 u_p-b9"
        style="background-color: rgba(94,191,101,.1);width: calc(100% - 0.72rem)"
      >
        <div class="flex flex-row flex-center" style="flex: 1">
          <img
            src="@/assets/wap/icon/line-square.png"
            class="u_m-r3"
            style="width: 24px;height: 24px;"
            @click="openShareLink({key: 'line'})"
            v-if="['ULO025', 'URT017', 'UUA001', 'ULO024'].includes(userinfo.platform_code)"
          />
          <img
            src="@/assets/wap/icon/fb.png"
            class="u_m-r3"
            style="width: 28px;height: 28px;"
            @click="openShareLink({key: 'fb'})"
          />
          <img
            src="@/assets/wap/icon/ig.png"
            class="u_m-r3"
            style="width: 28px;height: 28px;"
            @click="handleCopySuccess(igText)"
          />
          <img
            src="@/assets/wap/icon/zalo-square.png"
            class="u_m-r3 zalo-share-button"
            v-if="['ULO026', 'URT017', 'UUA001', 'ULO024'].includes(userinfo.platform_code)"
            @click="openShareLink({key: 'zalo'})"
            :data-href="sharelink"
            style="width:24px; height: 24px"
            data-oaid="579745863508352884"
            data-layout="2"
            data-color="blue"
            data-customize="false"
          />
          <img
            src="@/assets/wap/icon/gmail.png"
            class="u_m-r3"
            style="width: 28px;height: 28px;"
            v-if="['ULO025', 'URT017', 'UUA001', 'ULO024'].includes(userinfo.platform_code)"
            @click="openShareLink({key: 'mail'})"
          />
        </div>
        <el-button
          round
          size="small"
          type="info"
          hairline
          class="background-primary cf u_p-l15 u_p-r15"
          @click="showLink = false"
          >{{ $t('btn_cancel') }}</el-button
        >
      </el-row>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';

import {Watch} from 'vue-property-decorator';
import {Promoted, IPromotedForm} from '@/model';
import {Work} from './work';
import {ElMessage, ElTabs, ElTabPane, ElUpload, ElPopconfirm} from 'element-plus';
import {App, Format, Server} from '@/services';
import format from '@/services/format';
import {ISubdomain, ISubdomainResult, IMatchListResult, IActivity, IActivityResult} from '@/model';
import {contact} from '@/services/server/web/contact';
import {IContactResult} from '@/model/contact';
import {basic} from '@/config';

@Options({
  components: {
    ElTabs,
    ElTabPane,
    ElUpload,
    ElPopconfirm
  }
})
export default class Login extends Vue {
  userinfo = App.getUserinfo();
  basic = basic;
  submitLoading = false;
  checkIndex = 1;
  labelPosition = 'left';
  // 直播链接的分页数据
  withPage = 1;
  withTotal = 0;
  pageSize = 50;
  info: ISubdomain = {
    note: '',
    domain: ''
  };
  line: null | string = '';
  line_group: null | string = '';
  ig: null | string = '';
  fb: null | string = '';
  zalo: null | string = '';
  rules = {
    username: [{validator: Promoted.validateInput, trigger: 'blur'}],
    password: [{validator: Promoted.validateInput, trigger: 'blur'}]
  };
  link: Array<ISubdomain> = [];
  activeList: IActivity[] = [];
  showModel = false;
  isEdit = false;
  isAdd = false;
  isLoading = false;
  showLink = false;
  text = '';
  textarea = {
    innerText: ''
  };
  aid = '';
  sharedUrl = '';
  liveLink: Array<any> = [];
  livePopup = {
    show: false,
    data: null,
    imageUrl: ''
  };

  get sharelink() {
    return `${this.sharedUrl}/cz/${this.aid}/`;
  }
  get igText() {
    return `${this.text} \r\n ${this.sharelink}`;
  }

  @Watch('checkIndex')
  onCheckIndexChange(val: number) {
    console.log('onCheckIndexChange val: ', val);
    switch (val) {
      case 2:
        this.getLink();
        this.getActived();
        if (!document.querySelector('#zalo')) {
          const script = document.createElement('script');
          script.id = 'zalo';
          script.src = 'https://sp.zalo.me/plugins/sdk.js';
          document.body.append(script);
        }
        break;
      case 3:
        this.read();
        break;
      case 4:
        this.withPage = 1;
        this.withTotal = 0;
        this.liveLink = [];
        this.getLiveLink();
        break;
      case 5:
        this.withPage = 1;
        this.withTotal = 0;
        this.liveLink = [];
        this.getBtiLiveLink();
        break;
    }
  }
  created() {
    // this.getLink();
  }
  withPages(e: number) {
    this.withPage = e;
    const {checkIndex} = this;
    switch (checkIndex) {
      case 4:
        this.getLiveLink();
        break;
      case 5:
        this.getBtiLiveLink();
        break;
    }
  }
  handleAvatarSuccess(res: any) {
    this.livePopup.imageUrl = res.data.path;
  }
  beforeAvatarUpload(file: any) {
    const isJPG = file.type === 'image/jpeg' || 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      ElMessage('上传图片只能是 JPG 和 PNG 格式!');
    }
    if (!isLt2M) {
      ElMessage('上传图片大小不能超过 1MB!');
    }
    return isJPG && isLt2M;
  }
  uploadPic(item: any) {
    this.livePopup = {
      show: true,
      data: item,
      imageUrl: item.status === 1 ? '' : ''
    };
  }
  async fetchUploadPic() {
    this.isLoading = true;
    const data: any = this.livePopup.data;
    const res = await Server.Web.Operation.uploadLivePic<any>({
      cover: this.livePopup.imageUrl,
      agent_id: this.userinfo?.userId,
      match_code: data?.code
    });
    if (!(res instanceof Error)) {
      ElMessage(this.$t('rp_operate') + this.$t('ui_success'));
      this.livePopup = {
        show: false,
        data: null,
        imageUrl: ''
      };
      this.getLiveLink();
    } else {
      this.isLoading = false;
      ElMessage(JSON.stringify(res.name === '202') ? this.$t('openLive_error') : res.message);
    }
  }
  async endLive(item: any) {
    this.isLoading = true;
    const res = await Server.Web.Operation.endLive<any>({
      agent_id: this.userinfo?.userId,
      match_code: item?.code
    });
    if (!(res instanceof Error)) {
      ElMessage(this.$t('rp_operate') + this.$t('ui_success'));
      this.getLiveLink();
    } else {
      this.isLoading = false;
      ElMessage(res.message);
    }
  }
  async getActived() {
    const activityResult = await Server.Web.Operation.activityList<IActivityResult>();
    if (!(activityResult instanceof Error)) {
      this.activeList = activityResult.list[0].active;
    }
  }
  async getLink() {
    this.isLoading = true;
    const res = await Server.Web.Operation.linkMember<ISubdomainResult>();
    if (!(res instanceof Error)) {
      this.link = res.list;

      this.isLoading = false;
    }
  }
  async getLiveLink() {
    this.isLoading = true;
    const res = await Server.Web.Operation.newLiveLink<IMatchListResult>({
      page: this.withPage,
      size: this.pageSize,
      agent_id: this.userinfo?.userId
    });
    if (!(res instanceof Error)) {
      this.liveLink = res.data;
      this.withTotal = res.total;

      this.isLoading = false;
    }
  }
  async getBtiLiveLink() {
    this.isLoading = true;
    const res = await Server.Web.Operation.btiLiveLink<IMatchListResult>({
      page: this.withPage,
      size: this.pageSize
    });
    if (!(res instanceof Error)) {
      this.liveLink = res.data;
      this.withTotal = res.total;

      this.isLoading = false;
    }
  }
  getLiveTitle(item: any) {
    return `${item?.home.name} VS ${item?.away.name} (${this.$t(
      'game_time'
    )}：${Format.formatYMDHMS(new Date(item.estimate * 1000))})`;
  }
  copyLiveContent(item: any) {
    const {checkIndex} = this;
    // 由于不同的语言的918地址前缀不一样  所以我们这里用正则处理一下
    const lang: any = {
      vn: 'vi.',
      en: 'en.',
      th: ''
    };
    const replace = lang[this.$i18n.locale] ?? '';
    const cacheUserInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
    let link = '';
    // bti链接的处理方式不太一样
    if (checkIndex == 5) {
      link = `http://${this.userinfo?.domain}.aia998.com/m/${cacheUserInfo.platform_code}/${item.app_match_code}`;
      this.copy(link);
    } else {
      // 没有hosts就使用918链接
      const hosts = cacheUserInfo?.hosts?.length ? cacheUserInfo.hosts[0] : 'aia918.com';
      const url = `http://${replace}${hosts}`;
      link = `${this.getLiveTitle(item)} ${this.$t('view_game')} ${url}/match/detail/${
        item.id
      }?userId=${this.userinfo?.userId}&platformCode=${cacheUserInfo.platform_code}`;
      this.copy(link);
    }
  }
  async reGetImg(key: null | string) {
    const _key = key || 'line';
    const id = new Map([
      ['24', [52, 54]],
      ['9', [55, 57]],
      ['19', [58, 60]],
      ['20', [61, 63]],
      ['b', [64, 66]],
      ['16', [67, 69]],
      ['21', [70, 72]],
      ['23', [73, 75]],
      ['10', [76, 78]],
      ['22', [79, 81]],
      ['12', [82, 84]],
      ['17', [85, 87]],
      ['18', [88, 90]],
      ['15', [91, 93]],
      ['14', [94, 96]],
      ['a', [97, 99]],
      ['13', [100, 102]],
      ['11', [103, 105]],
      ['34', [118, 119]],
      ['35', [120, 121]],
      ['45', [154, 155]]
    ]);
    const hasHanldeIdgroup = id.get(this.aid) || [];
    const typeId = hasHanldeIdgroup
      ? hasHanldeIdgroup[
          key === null ? Math.floor(Math.random() * 2) : ['line', 'fb'].includes(_key) ? 0 : 1
        ]
      : null;
    if (typeId) {
      const [resultWord] = await Promise.all([
        fetch(`https://www.craw248000.com/api/getContentList?type_id=${typeId}&strip=1`)
      ]);
      // const res = rs.json()
      const [word] = await Promise.all([resultWord.text()]);
      const [wordJson] = [JSON.parse(word)];
      console.log('wordJson: ', wordJson);
      if (resultWord.status === 200) {
        if (wordJson.result !== 0) {
          this.text = wordJson.data[0]?.content;
        } else {
          this.text = '';
        }
      } else {
        this.text = '';
      }
    }
  }
  getSharedUrl({domain, id, type}: {domain: string; id: string; type: string}) {
    const idMap = new Map([
      ['football', 'b'],
      ['recommend', 'a']
    ]);
    const actId = idMap.get(type) || id;
    return `https://${domain}/cz/${actId}/`;
  }
  showClick(item: {type: string; atdcl_id: number | string; domain: string}) {
    const aidHandle = new Map([
      ['football', 'b'],
      ['recommend', 'a']
    ]);
    this.aid = aidHandle.get(item.type) || item.atdcl_id + '';
    this.reGetImg(null);
    this.showLink = true;
    this.sharedUrl = `${location.protocol}//${item.domain}`;
  }

  chnageText() {
    this.text = this.textarea.innerText;
  }
  handleCopySuccess(val: string) {
    this.copy(val);
    console.log({val});
    setTimeout(() => {
      // on confirm
      this.openShareLink({key: 'ig'});
    }, 500);
  }
  openShareLink({key}: {key: string}) {
    switch (key) {
      case 'line':
        window.open(
          `http://line.me/R/msg/text/?${this.text}%0A${encodeURIComponent(this.sharelink)}`
        );
        break;
      case 'fb':
        window.open(
          `https://www.facebook.com/dialog/share?href=${encodeURIComponent(
            this.sharelink
          )}&display=popup&ref=plugin&app_id=1085192225327998&quote=${this.text}`
        );
        break;
      case 'ig':
        // this.handleCopySuccess(evt)
        window.open(`http://www.instagram.com/`);
        break;
      case 'zalo':
        if (screen.height === window.innerHeight) {
          window.open(`https://chat.zalo.me/`);
        }
        break;
      case 'mail':
        window.open(`mailto:someone@yoursite.com?body=${this.igText}`);
        break;
    }

    this.showLink = false;
  }
  modelRef = reactive<IPromotedForm>({
    is_member: '1',
    user_account: '',
    password: ''
  });
  mounted() {
    this.modelRef.is_member = '1';
  }
  handleSubmit() {
    const res = this.$refs.formRef as any;
    this.submitLoading = true;
    res.validate(async (v: any) => {
      if (v) {
        const res = await Work.setMember<any>(this.modelRef);
        if (!(res instanceof Error)) {
          ElMessage(res.message);
          this.submitLoading = false;
        } else {
          ElMessage.error(res.message);
        }
      }
      this.submitLoading = false;
    });
  }
  resetFields() {
    this.modelRef = {
      is_member: '1',
      user_account: '',
      password: ''
    };
  }
  copy(val: string) {
    format.copyTextToClipboard(val);
    ElMessage(this.$t('ui_successful_copy'));
  }
  showEdit(item: {note: string}) {
    this.showModel = true;
    this.isEdit = true;
    this.info = {...this.info, ...item};
  }
  showAdd() {
    this.showModel = true;
    this.isAdd = true;
  }
  onCancel() {
    this.showModel = false;
  }
  onClosed() {
    this.isAdd = false;
    this.isEdit = false;
    this.info = {
      note: '',
      domain: ''
    };
  }
  async onAdd() {
    // this.loading = true;
    this.isLoading = true;
    const result = await Server.Web.Operation.addSubdomain<ISubdomainResult>({
      note: this.info.note
    });
    if (result instanceof Error) {
      ElMessage({
        message: result.message,
        type: 'error'
      });
    } else {
      ElMessage({
        message: result.message,
        type: 'success'
      });
      setTimeout(() => {
        this.getLink();
      }, 2500);
      this.onCancel();
    }
  }
  async onEdit() {
    this.isLoading = true;
    const result = await Server.Web.Operation.editSubdomain<ISubdomainResult>({
      note: this.info.note,
      domain: this.info.domain
    });
    if (result instanceof Error) {
      ElMessage({
        message: result.message,
        type: 'error'
      });
    } else {
      ElMessage.success({
        message: result.message,
        type: 'success'
      });
      this.getLink();
      this.onCancel();

      this.isLoading = false;
    }
  }
  async read() {
    this.isLoading = true;
    const result = await contact.read<IContactResult>();
    if (!(result instanceof Error)) {
      console.log(result, 'rs');
      const {line, ig, fb, zalo, lineGroup} = result.list[0];
      this.line = line;
      this.ig = ig;
      this.fb = fb;
      this.zalo = zalo;
      this.line_group = lineGroup;
    }
    this.isLoading = false;
  }
  async submit() {
    this.isLoading = true;
    const params = {
      line: this.line,
      ig: this.ig,
      fb: this.fb,
      zalo: this.zalo,
      line_group: this.line_group
    };
    if (!this.line_group) delete params.line_group;
    const result = await contact.edit<IContactResult>(params);
    if (result instanceof Error) {
      ElMessage({
        message: result.message,
        type: 'error'
      });
    } else {
      ElMessage({
        message: result.message,
        type: 'success'
      });
      this.read();
    }

    this.isLoading = false;
  }
}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
.form-title {
  margin-bottom: 30px;
}
.u_w--50p {
  width: 50%;
}
.u_w--100p {
  width: 100%;
}
.u_m--t20 {
  margin-top: 20px;
}

.form__field {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .form__title {
    padding-top: 5px;
    text-align: start;
    min-width: 150px;
  }
}
.c_input--longest {
  width: 100%;
  min-width: 270px;
}

.c_input--longest .el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.c_input--longest .el-input__inner {
  background-color: #f5f5f5;
}
.el-input__inner::placeholder {
  color: #c0c4cc;
}
[class*='c_input'] {
  border-radius: 5px;
}
[class*='c_input'] button,
[class*='c_input'] > div,
[class*='c_input'] input,
[class*='c_input'] select,
[class*='c_input'] textarea,
[class*='c_input'] .c_input__inner {
  border-radius: inherit;
  width: inherit;
}
[class*='c_button']:not(.c_button__text)[class*='--huge'] {
  font-size: 18px;
  line-height: 25px;
  padding: 4px 12px;
  min-width: 120px;
}
[class*='c_button']:not(.c_button__text) {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font: 14px 'Open Sans', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  &[disabled] {
    cursor: not-allowed;
    background-color: #e8e8e8;
    color: #999999;
  }
}
.u_main--btn {
  -webkit-box-shadow: 0px 3px transparent;
  box-shadow: 0px 3px transparent;
  font-size: 0.14rem;
  border-radius: 8px;
  background-color: #1f55f0;
  color: #ffffff;
  cursor: pointer;
}
:deep(.el-dialog--center .el-dialog__body) {
  padding: 0 20px 30px;
}
.title {
  font-size: 16px;
  color: #333333;
  display: flex;
  align-items: center;
  height: 25px;
}
.input-box {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.ipt {
  font-size: 14px;
  outline: none;
  border: none;
  flex: 1;
  color: #666666;
  background: transparent;
}
.active {
  .shared {
    width: 36px /* 25/25 */;
    height: 100%;
    padding: 5px 8px;
    cursor: pointer;
  }
}
.copy {
  margin-left: 8px;
  height: 32px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3b039;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  .icon {
    width: 16px;
    height: 16px;
    display: block;
  }
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
  &:focus {
    outline: rgb(229, 151, 0) auto 1px;
  }
}
.text-area {
  margin: 10px;
  background-color: transparent;
  border-radius: 10px;
  padding: 5px;
}
.high {
  color: #d46b6b;
}
.listBox {
  padding-bottom: 12px;
}
</style>
