import {Server} from '@/services';

export class Work {
  static data = {
    device: 0,
    is_member: 0,
    lang: 'zh_cn',
    password: '',
    user_account: ''
  };
  static setMember<T>(data: any) {
    this.data.is_member = data.is_member;
    this.data.user_account = data.user_account;
    this.data.password = data.password;
    return Server.Web.Common.setMemer<T>(this.data);
  }
}
