import doFetch from '../../fetch';
const ACCPATH = '/api/acc/front';

/** 1.1.19 取得會員資訊 (https://docs.google.com/document/d/1mSwKbuUV_l4Elb9x0kYj9jUUIAd8XZiVMAJtofsNAhM/edit#heading=h.ugs5lctk2e00)
/**
* 1.1.20 編輯用戶個人資訊 (https://docs.google.com/document/d/1mSwKbuUV_l4Elb9x0kYj9jUUIAd8XZiVMAJtofsNAhM/edit#heading=h.z0fkmsnekig2)
*
    {
      "real_name": String,
      "first_name": String,
      "last_name": String,
      "birth": Date,
      "gender": String,
      "email": String,
      "phone_number": String,
      "qq": String,
      "wechat": String,
      "line": String,
      "ig": String,
      "fb": String,
      "zalo": String,
      "lang": String
  }

*/
/**
 *  user card info
 * @param data
 */
const actions = {
  read: async <T>() => await doFetch<T>(`${ACCPATH}/v1/users/get_user_info`, 'POST', {}),
  edit: async <T>(data: object) =>
    await doFetch<T>(`${ACCPATH}/v1/users/edit_user_info`, 'POST', data)
};

export const contact = actions;
